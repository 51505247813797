@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

$bg-1: #d6d6d6;
$border-1: #f6f6f6;

#quiz-builder {
	.header-title {
		font-family: "Roboto";
		font-size: 18px;
		line-height: 125%;
	}

	.header-divider {
		height: 1px;
		background: $bg-1;
		margin-top: 16px;
	}

	.right {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 0;
		border-left: 1px solid $bg-1;
		.settings {
			display: flex;
			flex-direction: column;
			padding: 24px;
			flex-grow: 1;
			overflow-y: scroll;
			background: #f6f6f6;
			.quiz-name {
				margin-top: 25px;
				color: $bg-1;
			}
			height: 100vh;
			padding-bottom: 100px;
		}
		.submit-group {
			z-index: 2;
			flex-grow: 0;
			background-color: white;
			border-top: 1px solid $bg-1;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 16px 8px;
			gap: 16px;
			.submit-action {
				flex: 1;
			}
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	.disabled-color {
		margin-top: 26px;
		input {
			pointer-events: none;
		}
	}

	.quiz-color-container,
	.answer-color-container {
		position: relative;
		& > div {
			width: 100%;
		}
		.toggle-color-picker {
			cursor: pointer;
		}
		.twitter-picker {
			position: absolute !important;
			z-index: 999;
			right: 0;
			top: 87px;
			width: 220px !important;
		}
	}

	.answer-color-container {
		padding: 0px 16px 16px 16px;
		border-radius: 8px;
		box-sizing: border-box;
		margin-top: 8px;
		position: relative;
		span {
			color: white;
		}
		fieldset {
			&,
			&:hover,
			&:active,
			&:focus {
				border-color: rgba(255, 255, 255, 0.7);
			}
		}
		.twitter-picker {
			right: 0;
			top: 87px;
			width: 220px !important;
		}
	}

	.left {
		padding: 24px;
		overflow-y: scroll;
		height: 100vh;
		.add-buttons-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 8px;
			gap: 16px;
			background: $border-1;
			border: 1px solid $bg-1;
			border-radius: 4px;
			.add-button {
				font-weight: 500;
				font-size: 15px;
			}
			.separator-text {
				font-weight: 400;
				font-size: 14px;
				color: rgba(0, 0, 0, 0.6);
			}
		}
		.question-container {
			&.nested {
				border: 1px solid rgba(0, 0, 0, 0.2);
				border-left: 4px solid white;
			}
			border-radius: 8px;
			color: white;
			.question-header {
				border-radius: 8px 8px 0 0;
				display: flex;
				padding: 16px 24px;
				gap: 24px;
				align-items: center;
				justify-content: space-between;
				.question-title {
					font-size: 12px;
					line-height: 125%;
				}
			}
			.question-answers {
				border-radius: 0 0 8px 8px;
				&.my-0 {
					gap: 0;
				}
			}
			.question-actions {
				display: flex;
				gap: 32px;
				align-items: center;
			}
			.question-action {
				cursor: pointer;
			}
			.question-settings {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 40px 24px;
				gap: 24px;
				input {
					color: white;
				}
				.sub-container {
					display: flex;
					justify-content: space-between;
					.name {
						width: 72%;
					}
					.time {
						width: 25%;
					}
				}
			}

			.question-answers {
				display: flex;
				flex-direction: column;
				padding: 40px 24px;
				gap: 24px;
				.correct-answer {
					font-size: 11px;
					line-height: 125%;
					text-transform: uppercase;
					width: 60px;
				}
				.checkbox {
					width: 60px;
				}
			}
		}

		.section-settings {
			padding: 40px 24px;
			display: flex;
			flex-direction: column;
			gap: 24px;
		}
	}

	.custom-text-field {
		label {
			color: rgba(255, 255, 255, 0.7);
			display: flex;
			align-items: center;
		}
		svg {
			fill: white;
		}
		fieldset {
			&,
			&:hover,
			&:active,
			&:focus {
				border-color: rgba(255, 255, 255, 0.7);
			}
		}
		div[role="button"] {
			color: white;
		}
	}

	.mmd {
		margin: 16px 0;
	}
	.MuiFormHelperText-root {
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 0.4px;
		color: rgba(255, 255, 255, 0.7);
		margin: 6px 4px;
		width: 100%;
	}
}

.swatches-picker {
	width: 90% !important;
	position: absolute !important;
	left: 5%;
	z-index: 45;
	height: 200px !important;
	border-radius: 8px;
	& > div {
		width: 100% !important;
	}
}
