@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import "~bootstrap/scss/bootstrap";

#root {
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
	min-width: 100vw;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

a {
	text-decoration: none;
}

.divider {
	height: 2px;
	background: #d6d6d6;
}

* {
	font-family: "Roboto";
	font-weight: 700;
}

.step-container {
	gap: 8px;
	.step {
		height: 4px;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 2px;
		flex: 1;
		&.active {
			background-color: white;
		}
	}
}

.dashboard {
	.page-title {
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 125%;
	}

	.quiz-card {
		display: flex;
		flex-direction: row;
		align-items: center;
		background: #f6f6f6;
		border: 1px solid #d6d6d6;
		border-radius: 4px;
		padding: 24px;
		margin-top: 8px;
		justify-content: space-between;
		.quiz-name {
			font-weight: 700;
			font-size: 18px;
			line-height: 125%;
		}
		.quiz-count {
			font-size: 14px;
			line-height: 125%;
			color: rgba(0, 0, 0, 0.6);
			margin-top: 8px;
			font-weight: 400;
		}
		.quiz-actions {
			.quiz-action {
				font-weight: 500;
				font-size: 15px;
				line-height: 26px;
				margin-left: 26px;
				padding: 8px 22px;
			}
		}
	}
	.btn-create {
		font-family: "Roboto";
		font-weight: 500;
		font-size: 15px;
		line-height: 26px;
		color: white;
		width: 187px;
		height: 42px;
		background: #1976d2;
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
			0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
	}
}
