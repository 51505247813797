.icon-message {
	width: 136px;
	height: 136px;
	display: block;
	margin-top: 150px;
	margin-right: auto;
	margin-bottom: 40px;
	margin-left: auto;
}

.icon-message.warning {
	background: url("./icons/warning.svg") no-repeat;
}

.icon-message.error {
	background: url("./icons/error.svg") no-repeat;
}

.message {
	color: var(--grey-dark);
	font-size: var(--font-size-xlarge);
	font-weight: var(--font-weight-bold);
	text-align: center;
}
