.player-screen {
	* {
		font-weight: 700;
		margin: 0;
		padding: 0;
		font-family: "Roboto";
	}
	background-color: #e5e5e5;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	padding: 0 2px;
	.join-game-header {
		.step-container {
			height: 32px;
		}
		& > .d-flex {
			gap: 8px;
		}
		z-index: 3;
		color: white;
		height: 172px;
		background: #0071b3;
		box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.2);
		border-radius: 0px 0px 16px 16px;
		padding: 0 24px;
		.css-5xe99f-MuiLinearProgress-bar1 {
			background-color: white !important;
		}
		.title {
			text-transform: uppercase;
			font-size: 12px;
			line-height: 125%;
		}
		.guide {
			font-size: 24px;
			line-height: 125%;
		}
		.guide-detail {
			font-size: 16px;
			line-height: 125%;
			font-weight: 400;
			opacity: 0.7;
		}
		&.start-header {
			background: #f07000 !important;
			.step-container {
				height: 32px;
			}
		}
	}
	.join-game-body {
		margin-top: 127px;
		&.m-0 {
			height: calc(100vh - 172px);
		}
		.custom-text-field {
			max-width: 360px;
			border: 1px solid #d6d6d6;
			box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			background-color: white;
			fieldset {
				&,
				&:hover,
				&:active,
				&:focus {
					border-radius: 8px;
					border: 1px solid white;
				}
			}
			input {
				font-size: 32px;
				font-weight: 700;
				letter-spacing: 8px;
				&::placeholder {
					color: #bbbbbb;
					letter-spacing: normal;
				}
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				&[type="number"] {
					-moz-appearance: textfield;
				}
			}
		}
		.fixed-container {
			display: inline-block;
			vertical-align: center;
		}
	}

	span.MuiLinearProgress-bar {
		background-color: white !important;
	}
}

.player-game-screen {
	* {
		font-weight: 700;
		margin: 0;
		padding: 0;
	}
	background-color: #e5e5e5;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	padding: 0 8px;
	.play-game-header {
		z-index: 3;
		transition: all 500ms;
		color: white;
		min-height: 112px;
		padding: 0 24px 0 24px;
		box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.2);
		border-radius: 0px 0px 16px 16px;
		span.MuiLinearProgress-bar {
			background-color: white !important;
		}
		.main-title {
			font-size: 24px;
			line-height: 125%;
		}
		.small-title {
			text-transform: uppercase;
			margin-bottom: 8px;
			font-size: 12px;
			line-height: 125%;
		}
		.steps {
			height: 32px;
			gap: 8px;
			.step-container {
				flex-grow: 1;
			}
		}
		.result-text-box {
			.main-text {
				font-size: 24px;
			}
			.correct-point {
				margin-top: 8px;
				font-size: 16px;
			}
		}
	}
	.main-content {
		max-height: calc(100vh - 173px);
		overflow-y: scroll;
		min-height: 168px;
		color: white;
		gap: 8px;
		align-items: center;
		padding: 16px;
		&.flex-grow-0 {
			.answer-wrapper {
				min-height: 130px;
			}
		}
		&.leader-board-player {
			height: calc(100vh - 112px);
			padding-bottom: 0;
			padding: 0;
			max-height: unset;
			overflow-y: scroll;
			& > div {
				max-height: 650px;
			}
		}
		.leader-board {
			max-height: 650px;
			gap: 24px;
			.rank {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 200px;
				height: 200px;
				background: #ffffff;
				border: 1px solid rgba(0, 0, 0, 0.2);
				border-radius: 16px;
				gap: 8px;
				.text {
					font-size: 24px;
					font-weight: 400;
					color: #66aad1;
					line-height: normal;
				}
				.number {
					font-size: 64px;
					color: #0071b3;
					line-height: normal;
					sup {
						font-size: 40px;
					}
				}
			}
			.new-game-btn {
				padding: 16px 24px;
				gap: 8px;
				font-size: 20px;
				font-weight: 700;
				box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3),
					inset 0px -2px 0px rgba(0, 0, 0, 0.2);
				border-radius: 56px;
			}
		}
		.question-wrapper {
			width: 100%;
			max-width: 960px;
			flex-shrink: 0;
			flex-grow: 1;
			margin: auto;
			.progress-text {
				color: black;
				text-transform: uppercase;
				font-size: 12px;
			}
			.section-text {
				font-weight: 700;
				font-size: 32px;
				color: #0071b3;
				text-align: center;
			}
			.question-text {
				color: #0071b3;
				font-size: 32px;
				margin-top: 8px;
				margin-bottom: 24px;
				line-height: 125%;
			}
			img {
				max-width: 90%;
				border-radius: 8px;
				height: auto;
			}
		}
		.answer-wrapper {
			width: 100%;
			max-width: 960px;
			flex-shrink: 0;
			flex-grow: 1;
			&.wrong {
				opacity: 0.5;
			}
			.answer {
				cursor: pointer;
				&.disabled {
					pointer-events: none;
				}
				gap: 8px;
				width: 100%;
				height: 100%;
				padding: 16px;
				color: white;
				border: 1px solid rgba(0, 0, 0, 0.2);
				box-shadow: 0px -0.5px 1px rgba(0, 0, 0, 0.025),
					0px 2px 4px rgba(0, 0, 0, 0.1);
				border-radius: 8px;
				overflow-x: hidden;
				.answer-no {
					width: 48px;
					height: 48px;
					border-radius: 50%;
					font-size: 24px;
					border: 1px solid rgba(0, 0, 0, 0.1);
				}
				.answer-text {
					font-size: 20px;
					line-height: 125%;
				}
			}
		}
	}
	.footer {
		z-index: 3;
		padding: 16px 24px 32px 24px;
		height: 63px;
		background: #ffffff;
		box-shadow: 0px -0.25px 0.5px rgba(0, 0, 0, 0.0125),
			0px 1px 2px rgba(0, 0, 0, 0.05);
		border-radius: 16px 16px 0px 0px;
		.text {
			text-transform: uppercase;
			color: #0071b3;
			font-size: 12px;
			span {
				color: #0071b3;
			}
		}
	}
}
