.main-screen {
	* {
		margin: 0;
		padding: 0;
		font-family: "Roboto";
		font-weight: 700;
	}
	.cursor-pointer {
		cursor: pointer;
	}
	overflow-x: none;
	.left {
		color: white;
		position: relative;
		box-sizing: border-box;
		&.extand {
			width: calc(100vw - 24px);
		}
		.header {
			padding: 40px 80px;
			gap: 8px;
			height: 180px;
			.welcome {
				font-weight: 400;
				font-size: 32px;
			}
			.title {
				font-weight: 700;
				font-size: 48px;
			}
		}
		.main-content {
			height: calc(100vh - 180px);
			overflow-y: scroll;
			.ready-text {
				margin-top: calc(50vh - 180px);
				font-weight: 700;
				font-size: 48px;
			}
			.code-container {
				margin-top: calc(50vh - 280px);
				gap: 40px;
				.pin-container {
					.pin-code {
						height: 200px;
						width: 200px;
						background-color: white;
						border-radius: 16px;
						color: #0071b3;
						.pin-title {
							font-weight: 400;
							font-size: 24px;
							padding-top: 45px;
							line-height: 100%;
							opacity: 0.5;
						}
						.pin {
							font-weight: 700;
							font-size: 64px;
							margin-top: 16px;
							line-height: 100%;
						}
					}
				}
				.qr-code-container {
					.qr-code {
						height: 200px;
						width: 200px;
						background-color: white;
						border-radius: 16px;
						padding: 24px;
						.code {
							width: 176px;
							height: 176px;
						}
					}
				}
				.code-hint {
					font-weight: 400;
					font-size: 18px;
					color: #ffffff;
					margin-top: 16px;
				}
				.or {
					font-weight: 700;
					font-size: 18px;
				}
			}
		}
		.timer {
			filter: drop-shadow(0px -1.5px 3px rgba(0, 0, 0, 0.075))
				drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.3));
			z-index: 2;
			width: 112px;
			height: 192px;
			position: absolute;
			display: flex;
			align-items: center;
			padding-left: 18px;
			padding-top: 7px;
			right: 2px;
			top: 50vh;
			transform: translateY(-96px);
			background-image: url("../../../public/images/timer-bg.png");
			background-position: center;
			background-attachment: fixed;
		}
	}

	.right {
		.timer-right {
			width: 112px;
			height: 192px;
			position: absolute;
			display: flex;
			align-items: center;
			padding-left: 18px;
			padding-top: 7px;
			left: -112px;
			top: 50vh;
			transform: translateY(-96px);
			background-image: url("../../../public/images/timer-bg.png");
			background-position: center;
			background-attachment: fixed;
		}
		filter: drop-shadow(0px -1.5px 3px rgba(0, 0, 0, 0.075))
			drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.3));
		background: #efefef;
		height: 100vh;
		position: fixed;
		right: 0;
		&.minimize {
			width: 40px;
			.footer {
				color: transparent;
			}
		}
		.main-content {
			overflow-y: scroll;
			display: flex;
			flex-direction: column;
			align-items: center;
			height: calc(100vh - 64px);
			padding: 80px 0;
			.name {
				color: #0071b3;
				font-weight: 400;
				font-size: 20px;
				margin-bottom: 24px;
			}
		}
		.footer {
			padding: 16px 8px;
			height: 64px;
			background: #ffffff;
			box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.05),
				0px 4px 8px rgba(0, 0, 0, 0.2);
			font-size: 20px;
			color: #0071b3;
			text-transform: uppercase;
		}
	}
}

.master-screen {
	.left {
		background: white;
		transition: all 0.5s;
		&.extend {
			width: calc(100vw - 24px);
		}
		.header {
			padding: 0;
			height: 160px;
			.top {
				height: 120px;
				padding: 40px 40px 24px 40px;
				&.section-header {
					padding: 24px 40px 24px 40px;
				}
			}
			.title {
				font-size: 32px;
			}
			.title-small {
				font-size: 20px;
				margin-bottom: 8px;
				text-transform: uppercase;
			}
			.title,
			.title-small {
				line-height: 125%;
			}
			.actions {
				gap: 16px;
				.game-pin {
					margin-right: 8px;
					width: 96px;
					height: 61px;
					border: 1px solid rgba(0, 0, 0, 0.2);
					border-radius: 8px;
					.text {
						font-size: 12px;
						opacity: 0.5;
						line-height: 100%;
					}
					.code {
						font-size: 24px;
					}
				}
				.action {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					border-radius: 50%;
					width: 56px !important;
					height: 56px;
					border: 0;
					box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3),
						inset 0px -2px 0px rgba(0, 0, 0, 0.2);
				}
			}
			.step-container {
				height: 40px;
				padding: 18px 24px;
			}
		}
		.main-content {
			height: calc(100vh - 160px);
			width: 100%;
			box-sizing: border-box;
			.section-text {
				font-size: 48px;
			}
			.question-container {
				padding: 16px 0;
				max-height: 100%;
				overflow-y: scroll;
				width: auto;
				max-width: 960px;
				text-align: left;
				margin-right: 160px;
				margin-left: 40px;
				.question-number {
					font-size: 12px;
					opacity: 0.5;
				}
				.question-text {
					margin-top: 8px;
					margin-bottom: 24px;
					font-size: 32px;
					line-height: 125%;
				}
				.question-image {
					max-width: 480px;
					max-height: 360px;
				}
			}
			.toggle-button {
				position: absolute;
				left: calc(50% - 110px);
				bottom: 6vh;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 16px 24px;
				gap: 8px;
				height: 56px;
				background: #ffffff;
				box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3),
					inset 0px -2px 0px rgba(0, 0, 0, 0.2);
				border-radius: 56px;
				z-index: 1;
				font-size: 20px;
				font-weight: 700;
			}
			.results {
				max-height: 100%;
				overflow-y: scroll;
				width: auto;
				max-width: 960px;
				// height: calc(100vh - 424px);
				min-height: 464px;
				padding: 40px;
				gap: 24px;
				background: #ffffff;
				border-radius: 16px;
				.result {
					width: 80px;
					.count {
						margin-bottom: 24px;
						font-size: 32px;
					}
					.chart {
						border-radius: 8px;
						overflow: hidden;
						border: 1px solid rgba(0, 0, 0, 0.1);
						border-bottom: 0;
						.order-container {
							border: 1px solid rgba(0, 0, 0, 0.1);
							border-top: 0;
							height: 80px;
							.order {
								border-radius: 50%;
								font-size: 24px;
								color: white;
								width: 48px;
								height: 48px;
								border: 1px solid rgba(0, 0, 0, 0.1);
							}
						}
					}
				}
			}
		}

		.leader-board {
			padding: 40px 120px;
			.top-rank {
				gap: 48px;
				padding: 32px 80px 32px 32px;
				border-right: 1px solid rgba(0, 0, 0, 0.1);
				.rank-container {
					box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1),
						0px 8px 16px rgba(0, 0, 0, 0.4);
					border-radius: 8px;
					position: relative;
					max-height: 180px;
					flex-grow: 1;
					.circle {
						position: absolute;
						width: 64px;
						height: 64px;
						border-radius: 50%;
						top: -32px;
						left: -32px;
						background-color: white;
						font-size: 32px;
						box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.05),
							0px 4px 8px rgba(0, 0, 0, 0.2);
						sup {
							margin-top: 10px;
							font-size: 24px;
						}
					}
					.name,
					.point {
						color: black;
						display: flex;
						justify-content: flex-start;
						padding-left: 20%;
						border-radius: 8px 8px 0 0;
					}
					.name {
						font-size: 24px;
						padding-top: 24px;
						overflow-wrap: break-word;
						min-height: 50%;
					}
					.point {
						font-size: 20px;
						filter: brightness(0.9);
						color: rgba(0, 0, 0, 0.6);
						border-radius: 0 0 8px 8px;
						font-weight: 400;
						align-items: center;
					}
				}
				.r-1 {
					.circle {
						color: #f0b737;
					}
					.name,
					.point {
						background-color: #f0b737;
					}
				}
				.r-2 {
					.circle {
						color: #bebebe;
					}
					.name,
					.point {
						background-color: #bebebe;
					}
				}
				.r-3 {
					.circle {
						color: #ee7910;
					}
					.name,
					.point {
						background-color: #ee7910;
					}
				}
			}
			.rest-rank {
				gap: 16px;
				padding: 80px;
				font-size: 32px;
				overflow-y: scroll;
				sup {
					font-size: 20px;
				}
				.point {
					font-weight: 400;
				}
				.text-left {
					text-align: left;
				}
				.text-right {
					text-align: right;
				}
				.rank {
					width: 48px;
				}
				.name {
					padding-inline: 24px;
					overflow-wrap: break-word;
				}
				.point {
					width: 210px;
				}
			}
		}
	}
	.right {
		.main-content {
			padding: 40px;
			.answer-container {
				overflow-x: hidden;
				gap: 16px;
				.answer {
					position: relative;
					.mask {
						position: absolute;
						background-color: white;
						opacity: 0.45;
						border-radius: 8px;
						left: 0;
						right: 0;
						bottom: 0;
						top: 0;
						z-index: 3;
					}
					gap: 16px;
					flex-grow: 1;
					width: 100%;
					padding: 16px;
					color: white;
					border: 1px solid rgba(0, 0, 0, 0.2);
					box-shadow: 0px -0.5px 1px rgba(0, 0, 0, 0.025),
						0px 2px 4px rgba(0, 0, 0, 0.1);
					border-radius: 8px;
					.answer-no {
						width: 48px;
						height: 48px;
						border-radius: 50%;
						font-size: 24px;
						border: 1px solid rgba(0, 0, 0, 0.1);
					}
					.answer-text {
						font-size: 20px;
						overflow-wrap: break-word;
						line-height: 125%;
					}
				}
			}
		}
	}
}

.remove-scroll-bar {
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

.game-actions-modal-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 3;
	.game-actions-modal {
		width: 378px;
		background-color: white;
		box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.4);
		border-radius: 8px;
		overflow: hidden;
		.header {
			padding: 14px 16px;
			height: 48px;
			span {
				font-family: "Roboto";
				font-weight: 700;
				font-size: 16px;
				line-height: 125%;
				color: black;
			}
		}
		.content {
			padding: 24px 16px;
			font-size: 16px;
			font-family: "Roboto";
			font-weight: 400;
			color: #222;
			background: #efefef;
			border-top: 1px solid rgba(0, 0, 0, 0.2);
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}
		.actions {
			padding: 0 16px 0 32px;
			height: 72px;
			.action {
				color: #6b6b6b;
				&.cancel span {
					border-color: #6b6b6b;
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.left .main-content .leader-board {
		padding-left: 60px;
	}
	.master-screen {
		.top-rank {
			padding-left: 0 !important;
			padding-right: 32px !important;
		}
	}
}

@media only screen and (max-width: 768px) {
	.master-screen {
		.top-rank {
			border: 0 !important;
			padding-left: 0 !important;
			padding-right: 32px !important;
		}
		.rest-rank {
			padding-left: 0 !important;
		}
	}
}

.left,
.right {
	transition: all 0.5s;
}
